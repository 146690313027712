<template>
  <h2>1. Descripción del juego</h2>
  <p>
    El juego de futbol de Highlight Games “Soccerbet League” es una simulación única de partidos seleccionados
    aleatoriamente por una computadora para crear oportunidades de apuesta. 
  </p>
  <h2>2. Descripción del juego y duración</h2>
  <p>
    Una ronda sencilla de apuesta electrónica demora en average unos 5 minutos, la cual consiste del anuncio de los
    partidos que se darán acabo en esa semana, la cobertura del partido principal y los resultados. Un partido completo
    de una temporada de “Soccerbet League” consiste de rondas llamadas semanas cuya duración dependerán del país de
    origen de conformidad con la cantidad de equipos que cada liga contenga.
  </p>
  <p>
    Liga Inglesa: 38 rondas de 190 minutos en total Liga Italiana: 30 rondas de 150 minutos en total Liga Griega: 26
    rondas de 130 minutos en total
  </p>
  <p>
    La selección de eventos de futbol se lleva acabo mediante un generador de números aleatorios (GNA o RNG por sus
    siglas en inglés) por lo cual es estadísticamente independiente, aleatoria e impredecible.
  </p>
  <h2>3. Mercados de apuesta</h2>
  <h3>3.1. Goles exactos</h3>
  <p>
    Número exacto de goles se deberán anotar en un partido. En esta apuesta, el jugador deberá predecir el número
    preciso de goles independientemente de quién los haya anotado.
  </p>
  <h3>3.2. Goles exactos equipo local</h3>
  <p>Apuesta en el número exacto de goles que el equipo local anotará en el Partido.</p>
  <h3>3.3. Goles exactos equipo visitante</h3>
  <p>Apuesta en el número exacto de goles que el equipo visitante anotará en el Partido.</p>
  <h3>3.4. Ambos Anotan</h3>
  <p>
    Apuesta en la que ambos equipos deben anotar al menos un gol hacienda que el resultado sea 1-1 en Adelante. Este
    mercado brinda la oportunidad a los jugadores de aprovechar un coeficiente de apuesta favorable. También conocido
    como ambos equipos anotan (AEA).
  </p>
  <h3>3.5. Resultado correcto</h3>
  <p>
    La apuesta de resultado correcto es una apuesta en donde el jugador indica cuál será el resultado final de un
    paritdo de futbol con un número máximo de 6 goles por distribuir en cada partido.
  </p>
  <h3>3.6. Doble oportunidad</h3>
  <p>
    La apuesta de doble oportunidad permite cubrir dos de tres posibles resultados en un partido de futbol con una
    apuesta. Equipo local o empate (1x): esta apuesta permite cubrir la posibilidad de que el equipo local gane o
    empate; equipo visitante o empate (2x): esta apuesta permite cubrir la posibilidad de que el equipo visitante gane o
    empate; equipo local o equipo visitante (12): La apuesta gana si hay un ganador, el equipo local o el visitante.
  </p>
  <h3>3.7. Resultado del Partido (1X2)</h3>
  <p>Apuesta en la que el equipo local gana (1), el equipo visitante gana (2) o el partido termina empatado (X).</p>
  <h3>3.8. Resultado del Partido + Ambos Anotan (1X2 + BTTS)</h3>
  <p>
    Apuesta en el resultado del partido (1 gana el local, X empate, 2 gana el visitante) y ambos equipos anotan o no.
  </p>
  <ul>
    <li>1 + Gol – Equipo local gana y ambos equipos anotan al menos un gol. [ej., 3-1].  </li>
    <li>1 + No Gol – Equipo local gana y el equipo visitante no anota [ej., 1-0].  </li>
    <li>X + Gol – Empate y ambos equipos anotan. [ej., 1-1, 2-2].  </li>
    <li>X + No Gol – Empate y nadie anota  [Solo 0-0].  </li>
    <li>2 + Gol – Equipo visitante gana y ambos equipos anotan al menos un gol [ej., 1-2, 1-3]. </li>
    <li>2 + No Gol – Equipo visitante gana y el equipo local no anota [ej., 0-1, 0-2].</li>
  </ul>
  <p> </p>
  <p></p>

  <h3>3.9. Resultado del Partido + Más/Menos 1.5 goles totales</h3>
  <p>
    Apuesta en el resultado del partido 1X2 (equipo local gana, empate o equipo visitante gana) y hay más o menos de 1.5
    goles totales anotados en el partido por ambos equipos.
  </p>
  <h3>3.10. Resultado del Partido + Más/Menos 2.5 goles totales</h3>
  <p>
    Apuesta en el resultado del partido 1X2 (equipo local gana, empate o equipo visitante gana) y hay más o menos de 2.5
    goles totales anotados en el partido por ambos equipos.
  </p>
  <h3>3.11. Total de goles (1 a 2, 1 a 3, 1 a 4, 2 a 3, 3 a 4)</h3>
  <p>
    Apuesta en la que el juego tendrá de 1 a 2, 1 a 3, 1 a 4 o 2 a 4 goles como resultado final Esta es una apuesta Si o
    No. A bet that the game will have either 1 to 2,1 to 3, 1 to 4 or 2 to 4 total goals as the final result. This is a
    YES or NO bet.
  </p>
  <h3>3.12. Más/Menos 1.5 Goles del local</h3>
  <p>Apuesta en la que el equipo local anotará más o meno sde 1.5 goles en el partido.</p>
  <h3>3.13. Más/Menos 1.5 Goles del visitante</h3>
  <p>Apuesta en la que el equipo visitante anotará más o menos de 1.5 goles en el partido.</p>
  <h3>3.14. Más/Menos de 1.5, 2.5, 3.5, 4.5 goles en total</h3>
  <p>
    Apuesta en la que el total de goles anotados por ambos equipos es más o menos de 1.5, 2.5, 3.5 o 4.5 en el partido.
  </p>
</template>
